html{
	height: 100%;
	}

body {
	margin: 0;
	padding: 0;
	font-family: 'Arial', sans-serif;
	background: linear-gradient(#141e30, #243b55);	
}

.login-box {
	position: absolute;
	top: 40%;
	left: 50%;
	width: 400px;
    min-width: 400px;
	padding: 40px;
	transform: translate(-50%, -50%);
	background: rgba(0,0,0,.5);
	box-sizing: border-box;
	box-shadow: 0 15px 25px rgba(0,0,0,.6);
	border-radius: 10px;
    text-align: center;
}

.login-box h2 {
	margin: 0 0 30px;
	padding: 0;
	color: #F1EEE6;
	text-align: center;
}

.login-box img {
    margin-bottom: 60px;
}

.login-box button {
    width: 280px;
    height: 40px;
    font-size: 20px;
    padding: 0;
}


.login-box .user-box {
	position: relative;
    margin-top: 20px;
}

.login-box .error-box h4 {
	color: red;
}

.login-box .user-box input {
	width: 100%;
	padding-top: 10px;
	font-size: 18px;
	color: #F1EEE6;
	margin-bottom: 48px;
	border: none;
	border-bottom: 1px solid #F1EEE6;
	outline: none;
	background: transparent;
	font-family: 'Lato';
}

.login-box .user-box label {
	position: absolute;
	top: -40px;
	left: 0px;
	padding: 10px 0;
	font-size: 16px;
	color: #03E9F4;
	pointer-events: none;
	transition: .5s;
}

.login-box .user-box input:focus ~ label, .login-box .user-box input:valid ~ label {
	top: -40px; 
	left: 0px; 
	color: #03E9F4;
	font-size: 16px;
    /* font-family: Lato; */
}






