#updateSubscriber{
  width: 60%;
  margin: 0 auto;
  margin-top: -2%;
  height: 90vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#blacklist{
  width: 50%;
  margin: 0 auto;
  margin-top: 7%;
  height: 35vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#unsub{
  width: 50%;
  margin: 0 auto;
  margin-top: 7%;
  height: 35vh;
  overflow-x: hidden;
  padding: 0 !important;
}